import React, { useState, useEffect } from 'react';
import Lottie from "lottie-react";
import { useWindowWidth } from './searchPharmacy/useWindowWidth'; // Custom hook to get window width
import { MotionTextHeading, MotionTextPara } from './motion/MotionText'; // Custom components for animated text
import ariaAnnounce from '../utilities/useAnnounceText'; // Accessibility utility for announcing text
import animationData from "./animation.json";

const InfoSection = () => {
    const { windowWidth } = useWindowWidth();
    const isTab = windowWidth > 766 && windowWidth <= 992;
    const isDesktop = windowWidth > 992;

    const [text1, setText1] = useState('Searching for prescription discounts');
    const [text2, setText2] = useState('discounts up a lot of savings');

    useEffect(() => {
        const interval1 = setInterval(() => {
            setText1((prevText) =>
                prevText === 'Searching for prescription discounts'
                    ? 'Checking manufacturer savings offers'
                    : 'Searching for prescription discounts'
            );
        }, 3000);

        const interval2 = setInterval(() => {
            setText2((prevText) =>
                prevText === 'discounts up a lot of savings'
                    ? "so much savings we're off'er rockers"
                    : 'discounts up a lot of savings'
            );
        }, 3000);

        return () => {
            clearInterval(interval1);
            clearInterval(interval2);
        };
    }, []);

    const getContainerStyle = () => {
        if (isDesktop) {
            return {
                position: "absolute",
                left: 80,
                top: 160,
                zIndex: 1,
                opacity: 0.8,
            };
        }
        return {
            position: "fixed",
            left: "50%",
            top: "20%",
            transform: "translate(-50%, -50%)",
            textAlign: "center",
            width: "max-content",
            zIndex: 1,
            opacity: 0.8,
        };
    };

    const getHeadingStyle = () => {
        if (isDesktop) {
            return {
                fontWeight: 600,
                maxWidth: 489,
                fontSize: 40,
            };
        } else if (isTab) {
            return {
                fontWeight: 600,
                maxWidth: 520,
                fontSize: 42,
            };
        }
        return {
            fontWeight: 600,
            maxWidth: 300,
            fontSize: 22,
        };
    };

    return (
        <div className='info-section-loader' style={getContainerStyle()}>
            <MotionTextHeading text={text1} style={getHeadingStyle()} key={text1} />
            <MotionTextPara text={text2} style={{ fontSize: 14 }} key={text2} />
        </div>
    );
};

const Loader = () => {
    const { windowWidth } = useWindowWidth();
    const isTab = windowWidth > 766 && windowWidth <= 992;
    const isDesktop = windowWidth > 992;

    const getMarginLeft = () => {
        if (isDesktop) return 200;
        if (isTab) return -700;
        return -1380;
    };

    useEffect(() => {
        ariaAnnounce("please wait");
    }, []);

    return (
        <div
            style={{
                position: 'fixed',
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                overflow: 'hidden',
                minWidth: '1800px',
                zIndex: 100000,
                background: 'linear-gradient(50deg, rgb(255, 255, 255) 0%, rgb(209 255 243) 100%)',
            }}
        >
            <InfoSection />
            <Lottie
                animationData={animationData}
                background="transparent"
                speed={1}
                loop
                autoplay
                style={{
                    width: '100%',
                    height: '120%',
                    marginLeft: getMarginLeft(),
                }}
            />
        </div>
    );
};

export default Loader;
