import { useEffect, useState, forwardRef, useImperativeHandle } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import editPencil from "../../assets/images/RxLink assets/new assets/edit pen.svg"
import PopupCouponCard from "../coupan/PopupCouponCard";
import { setSavedNpi, setTriggerModelFromCoupon, setPharmaInfo } from "../../reducers/medication";
import arrowUp from '../../assets/images/RxLink assets/new assets/arrow/up.svg'
import {
  AnimatePresence,
  motion,
  useAnimationControls,
} from "framer-motion";
import { store } from "../../store";
import { setCouponData, setShowCoupon } from "../../reducers/medication";
import { searchPharmacyLogo } from "../PharmacyLogo";
import { useWindowWidth } from "../searchPharmacy/useWindowWidth";
import { getCrxBestPrice } from "../../utilities/GetPrice";
import ariaAnnounce from "../../utilities/useAnnounceText";
import { normalizeCompanyName } from "../../utilities/getNormalizedName";
import HCDLSection from "./HcdlSection";
import { useGetUser } from "../../utilities/useGetUser";
import { saveProfile } from "../../reducers/submitProfile";
import { successToast, errorToast } from "../toast/toast";
import { NPIMappingList } from "../searchPharmacy/NPIMappingList";


const RightReviewCoupon = forwardRef(({
  medParam,
  valParam,
  setSavingOptionsPram,
  savingOptionParam,
  refParam,
  medDrugData,
  ownPosition,
  // pharmacyInfo,
  pricesList,
  medicationInfo,
  bestPackage,
  insuranceDetail,
  prismaPrices
}, ref) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [viewSavingOptions, setSavingOptions] = useState(false);
  useState();
  const [savingPercentage, setSavingPercentage] = useState(0);
  const [objPopupCoupon, setObjPopupCoupon] = useState({});
  const [isOpenCouponModal, setIsOpenCouponModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isSaveValueForCoupon, setSaveValueForCoupon] = useState({});
  const [memberAssign, setMemberAssign] = useState({});
  const user = useGetUser();

  const [hdclCheck, setHcdlCheck] = useState(false);

  const isProd = process.env.REACT_APP_ENV == "PRODUCTION" ? true : false;

  const [isShowPencilDiv, setShowPencilDiv] = useState(false);
  const [pharmacyInfo, setPharmacyInfo] = useState({});
  const [cheaperOptionAvailable, setCheaperOptionAvailable] = useState(false);
  const controlsAnimation = useAnimationControls();

  const reduxState = store.getState();
  const rxSenseCardInfo = reduxState.membersAssignAPI.rxSenseCardInfo;
  const hippoCardInfo = reduxState.membersAssignAPI.hippoCardInfo;

  const pharmacyList = useSelector((state) => state.medication.pharmacyList);
  const insuranceInfo = useSelector((state) => state.global.insuranceInfo);

  const selectedPharmacy = pharmacyList?.length > 0 ? pharmacyList.filter(item => !!item.isDefault) : [];


  const { windowWidth } = useWindowWidth();
  const submitHcdl = ()=> {
    if (loading) return;
    setLoading(true);
    let body = {
      firstName: user?.firstName,
      middleName: user?.middleName,
      lastName: user?.lastName,
      dob: user?.dob,
      gender: user?.gender,
      bin: user?.bin,
      pcn: user?.pcn,
      groupNumber: user?.groupNumber,
      memberId: user?.memberId,
      appUserSeqNo: user?.appUserSeqNo,
    };
    let extendedBody = {
      insInfoSeqNo: insuranceInfo?.insInfoSeqNo,
      patientSeqNo: user?.patientSeqNo,
      address: insuranceInfo?.address,
      hcdlFlag: hdclCheck,
      formSubmitted: true,
      hasAlerts: false,
      ...body
    }
    dispatch(saveProfile(extendedBody))
      .unwrap()
      .then((response) => {
        setLoading(false);
        if (response?.statusCode === 200 & response?.status === "OK")
          if (response?.statusCode === 200 & response?.status === "OK") {
            localStorage.setItem("user", JSON.stringify({ ...user, dob: response?.data?.dob, gender: response?.data?.gender, hcdlFlag: response?.data?.hcdlFlag, hasInsurance: true }));

            // if hcdl enrollment link received
            // if (response?.data?.hcdlEnrollmentLink){
            //   navigate("/medications");
            //   window.open(response?.data?.hcdlEnrollmentLink, "_self", "noopener");
            // }

            // other wise if no hcdl enrollment link received
            // else {
            // successToast(response?.message, "showAlert");
            // navigate("/medications");
            // }
          } else if (response?.status !== "OK") {
            errorToast(response?.message);
          }
      })
      .catch((e) => {
        setLoading(false);
      });

  }
  useEffect(() => {
    const getPrismaPrices = ()=>{
      let totalPrice = null;
      prismaPrices?.map((drug)=> {
        if(drug?.price != null){
          totalPrice = totalPrice == null ? parseFloat(drug?.price) : totalPrice + parseFloat(drug?.price); 
        }
      });
      return totalPrice;
    }
    if (bestPackage?.length > 0) {
      // in case of selected pharamcy is not available
      // check if any drug has inurance price available
      const checkInsuranceAvailable = () => {


        let hasInurancePrice = false;
        let packagePrice = null;
        let pharmacy = bestPackage[0].chain == "local" ? bestPackage[0]?.pharmaciesList[0] : bestPackage[0];
        let checkPrismaPharmacy = NPIMappingList(valParam?.pharmacyName);
        if (checkPrismaPharmacy != "") {
          prismaPrices?.map((drug) => {
            if (drug?.price != null) {
              hasInurancePrice = true;
              packagePrice = packagePrice == null ? parseFloat(drug?.price) : packagePrice + parseFloat(drug?.price);
            }
          })
        }
        else {
          pharmacy?.drugs?.map((drug) => {
            if (drug?.hasInsurance) {
              hasInurancePrice = true;
              packagePrice = packagePrice == null ? drug?.bestPrice : packagePrice + drug?.bestPrice;
            }
          })
        }

        return {
          hasInurancePrice,
          packagePrice
        }
      }
      let selectedPharmacy = bestPackage?.filter((x, i) => normalizeCompanyName(x?.chain) == normalizeCompanyName(valParam?.chain) || normalizeCompanyName(x?.chain) == normalizeCompanyName(valParam?.pharmacyName)?.toLowerCase());
      if (selectedPharmacy.length == 0) {
        let local = bestPackage?.filter((item) => item?.chain == "local");
        if (local.length > 0) {
          selectedPharmacy = local?.[0]?.pharmaciesList?.filter((x, i) => normalizeCompanyName(x?.chain) == normalizeCompanyName(valParam?.chain) || normalizeCompanyName(x?.chain) == normalizeCompanyName(valParam?.pharmacyName)?.toLowerCase());
        }
      }
      if (selectedPharmacy.length > 0) {
        setPharmacyInfo(selectedPharmacy[0]);
        dispatch(setPharmaInfo(selectedPharmacy[0]))
      }
      else if (selectedPharmacy.length == 0 && checkInsuranceAvailable()?.hasInurancePrice) {
        setPharmacyInfo({
          ...valParam,
          hasInsurance: true,
          bestPrice: checkInsuranceAvailable()?.packagePrice
        });
        dispatch(setPharmaInfo({
          ...valParam,
          hasInsurance: true,
          bestPrice: checkInsuranceAvailable()?.packagePrice
        }))
      }
      else if (selectedPharmacy.length == 0 && !checkInsuranceAvailable()?.hasInurancePrice) {
        setPharmacyInfo({
          ...valParam,
          hasInsurance: getPrismaPrices() != null ? true : false,
          bestPrice: getPrismaPrices()
        });
        dispatch(setPharmaInfo({
          ...valParam,
          hasInsurance: getPrismaPrices() != null ? true : false,
          bestPrice: getPrismaPrices()
        }))
      }
    }
    else if (bestPackage?.length === 0) {
      //check if user has selected a pharmacy
      if (valParam?.pharmacyName) {
        //function to get prismaprices
        setPharmacyInfo({
          ...valParam,
          hasInsurance: getPrismaPrices() != null ? true : false,
          bestPrice: getPrismaPrices()
        });
        dispatch(setPharmaInfo({
          ...valParam,
          hasInsurance: getPrismaPrices() != null ? true : false,
          bestPrice: getPrismaPrices()
        }));
      }

      else {
        setPharmacyInfo({});
        dispatch(setPharmaInfo({}))
      }
    }
  }, [bestPackage, prismaPrices])

  useEffect(() => {
    let cheaperOption = bestPackage.some(
      (option) => option.bestPrice < pharmacyInfo?.bestPrice
    );
    if (bestPackage?.length > 0 && !pharmacyInfo) {
      setCheaperOptionAvailable(true);
    }
    else {
      setCheaperOptionAvailable(cheaperOption);
    }
  }, [pharmacyInfo])

  useEffect(() => {
    // console.log({valParam});
    let priceToAdd = 0;
    let priceToMinus = 0;
    pharmacyInfo?.drugs?.map((dr) => {
      if (dr?.selectedDrug?.connectiveRxOffer?.hasCard == 1) {
        priceToAdd += parseFloat(dr?.selectedDrug?.connectiveRxOffer?.price);
        priceToMinus += dr.bestPrice;
      }
    })
    let newSum = pharmacyInfo?.bestPrice - priceToMinus + priceToAdd;
    let costPercentage = 0;
    costPercentage = (newSum / pharmacyInfo?.estRetailPrice) * 100;
    let percentage = 100 - costPercentage;
    setSavingPercentage(percentage);
  }, [pharmacyInfo]);

  useImperativeHandle(ref, () => ({
    couponModelToCouponScreen() {
      if (isOpenCouponModal) {
        setIsOpenCouponModal(false);
        dispatch(setSavedNpi(valParam.npi))
        savingMap(pharmacyInfo, medParam, savingPercentage)
      }
    },
    couponScreenToCouponModel() {
      dispatch(setTriggerModelFromCoupon(false))
      savingMapTab(pharmacyInfo, medParam, savingPercentage)
    },
  }));

  const savingMap = (sub_element, pharmacyDrugsInfo) => {
    let obj = {
      drug: sub_element,
      pharmacyDrugsInfo: pharmacyDrugsInfo,
      savingPercentage: savingPercentage,
      memberAssign: memberAssign,
      isPharmacyInsShow: false,
      medDrugData: medDrugData,
      cheaperOptionAvailable: cheaperOptionAvailable
    };
    setSavingOptions(false);
    setSavingOptionsPram(false);
    dispatch(setCouponData(obj));
    dispatch(setShowCoupon(true));
  };

  const savingMapTab = (sub_element, pharmacyDrugsInfo) => {
    let obj = {
      drug: sub_element,
      pharmacyDrugsInfo: pharmacyDrugsInfo,
      savingPercentage: savingPercentage,
      memberAssign: memberAssign,
      isPharmacyInsShow: false,
      medDrugData: medDrugData,
    };
    setSaveValueForCoupon(obj);
    setIsOpenCouponModal(true);
    dispatch(setCouponData(obj));
    setObjPopupCoupon(obj);
    setSavingOptions(false);
    setSavingOptionsPram(false);
  };

  useEffect(() => {
    setSavingOptions(savingOptionParam);
    // on select rightReview coupon close
  }, [savingOptionParam]);

  const viewOptions = () => {
    if (viewSavingOptions) {
      setSavingOptions(false);
      setSavingOptionsPram(false);
    } else {
      setSavingOptions(true);
      setSavingOptionsPram(true);
    }
  };

  const navigationToCoupon = () => {
    setIsOpenCouponModal(false);
    navigate("/coupon", { state: isSaveValueForCoupon });
  };

  const hideCoupon = () => {
    setIsOpenCouponModal(false);
  };

  const handlePencilAction = () => {
    setIsOpenCouponModal(false);
    setSavingOptions(true);
    setSavingOptionsPram(true);
    window.scroll(0, 0);
  };

  useEffect(() => {
    if (rxSenseCardInfo?.bin) {
      localStorage.setItem("rxSenseCard", JSON.stringify(rxSenseCardInfo));
    }
  }, [rxSenseCardInfo])

  const showHidePencil = () => {
    if (isShowPencilDiv) {
      setShowPencilDiv(false);
      controlsAnimation.stop({ y: 100, transition: { duration: 0.5 } });
    } else {
      setShowPencilDiv(true);
      controlsAnimation.start({ y: 100, transition: { duration: 0.5 } });
    }
  };
  const variants = {
    open: {
      opacity: 1,
      scaleY: 1,
      y: 0,
      transition: { duration: 0.5, ease: "easeInOut" },
    },
    closed: {
      opacity: 1,
      scaleY: 1,
      y: 0,
      transition: { duration: 0.5, ease: "easeInOut" },
    },
  };
  const init = isShowPencilDiv ? { y: 100 } : { y: -155 };

  console.log({ rxSenseCardInfo, hippoCardInfo, pharmacyInfo })

  // const showHcdl = user?.hasInsurance && !user?.hcdlEnrolled;

  const [showHcdl, setShowHcdl] = useState(user?.hasInsurance && !user?.hcdlEnrolled && !isProd);

  return (
    <>
      <section className="couponTabFix">

        <>
          <AnimatePresence>
            {medDrugData?.length > 0 && (<>


              <motion.div
                className="col-sm-12 col-md-12 col-lg-12 couponMobFixed"
                controls={controlsAnimation}
                layout
                key={isShowPencilDiv ? "open" : "closed"}
                animate={isShowPencilDiv ? "open" : "closed"}
                variants={variants}
                initial={init}
              >
                <motion.div
                  className="medications_inner medications_inner_coupon dlr-Sup mt-0 mb-0 pos_rel"
                >
                  <motion.div className="row mobileView-tabView ">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                      <div className="angleUpMain ">

                        <div className=" angleUp">
                          {/* <MotionIcon> */}
                          <motion.i
                            initial={{ rotate: !isShowPencilDiv ? 180 : 0 }}
                            animate={{ rotate: !isShowPencilDiv ? 0 : 180 }}
                            transition={{ duration: 0.3 }}
                          >  <a href="javascript:void(0)" onClick={() => showHidePencil()}>
                              <img src={arrowUp} alt="See pharmacy information" /></a>
                          </motion.i>
                          {/* </MotionIcon> */}
                        </div>

                      </div>
                    </div>
                  </motion.div>
                  <motion.div className="row">
                    <div className="col-7 col-sm-7 col-md-8 col-lg-12">
                      {(pharmacyInfo?.bestPrice > 0 || pharmacyInfo?.hasInsurance) ? (
                        <>
                          <h2 className="totalPocket" aria-label="TOTAL OUT OF POCKET">TOTAL OUT OF POCKET</h2>
                          <motion.div className="med-Flex" tabIndex={0} >
                            <span style={{ fontSize: 27, lineHeight: 1.3, fontWeight: "bold" }} className="webView div-hidden" aria-label={"$" + getCrxBestPrice(pharmacyInfo) + " " + Math.round(savingPercentage) + "% off"}>
                              <sup>
                                <b>$&nbsp;</b>
                              </sup>
                              {
                                getCrxBestPrice(pharmacyInfo, true)
                              }{" "}
                            </span>
                            <span style={{ fontSize: 27, lineHeight: 1.3, fontWeight: "bold" }} className="tabView" aria-label={"$" + getCrxBestPrice(pharmacyInfo) + " " + Math.round(savingPercentage) + "% off"}>
                              <sup style={{ fontSize: 11 }}>
                                <b>$&nbsp;</b>
                              </sup>
                              {
                                getCrxBestPrice(pharmacyInfo, true)
                              }{" "}
                            </span>
                            <span className="mobileView" style={{ display: "flex", fontSize: 20, fontWeight: "bold", lineHeight: 1.2 }} aria-label={"$" + getCrxBestPrice(pharmacyInfo) + " " + Math.round(savingPercentage) + "% off"}>
                              <sup style={{ paddingTop: 10, fontSize: 11 }}>
                                <b>$&nbsp;</b>
                              </sup>
                              {
                                getCrxBestPrice(pharmacyInfo, true)
                              }{" "}
                            </span>
                            {savingPercentage > 0 && (
                              <p className="font13 flex-end" aria-hidden={true}>
                                {Math.round(savingPercentage)}%&nbsp;off{" "}
                              </p>
                            )}
                          </motion.div>
                        </>) : (<>Not Available</>)}
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-12 webView div-hidden copun_pharmacy">
                      <h3 className="hrDiv totalPocket ">PHARMACY</h3>

                      <motion.div className="review-coupon ">
                        <div className="row ">
                          <div className="col-sm-3 col-md-3 col-lg-3 autoMargin">
                            <div className="pharmacyImg_div">
                              <img
                                src={searchPharmacyLogo(pharmacyInfo?.pharmacyName, pharmacyInfo?.logo?.small, pharmacyInfo?.chain?.toLowerCase())}
                                className="img-fluid"
                                alt={pharmacyInfo?.pharmacyName + ' Logo'}
                              // style={{maxWidth: 60}}
                              />
                            </div>
                          </div>
                          <div className="col-sm-7 col-md-7 col-lg-7 p-0 autoMargin">
                            <div className="" style={{ textAlign: "left" }}>
                              <p className="shopName">{((pharmacyInfo?.bestPrice > 0 || pharmacyInfo?.hasInsurance) && pharmacyInfo?.pharmacyName) ? pharmacyInfo?.pharmacyName : selectedPharmacy?.length > 0 ? selectedPharmacy[0]["pharmacyName"] : ""}</p>
                            </div>
                          </div>
                          <div className="col-sm-2 col-md-2 col-lg-2 cursor-pointer edit-Icon autoMargin text-end">
                            <a href="javascript:void(0)" onClick={() => viewOptions()}>
                              <img className="" src={editPencil} alt="Change Pharmacy" />
                            </a>
                          </div>
                        </div>
                      </motion.div>

                    </div>
                    <div
                      className="col-5 col-sm-5 col-md-4 col-lg-12 autoMargin"
                      transition={{ type: "tween" }}
                      whileTap={{ scale: 0.95 }}
                    >
                      {(pharmacyInfo?.bestPrice > 0 || pharmacyInfo?.hasInsurance) && (<>
                        <button
                          className="btn_success webView div-hidden review-coupon-button mt-2"
                          onClick={() => {
                            ariaAnnounce("My medication savings")
                            dispatch(setSavedNpi(valParam.npi));
                            savingMap(pharmacyInfo, bestPackage, savingPercentage);
                          }
                          }
                        >
                          Review coupons
                        </button>
                        {/* Mobile btn */}
                        <button
                          className="btn_success mobileView seeCoupon"
                          onClick={() =>
                            savingMapTab(pharmacyInfo, bestPackage, savingPercentage)
                          }
                        >
                          See coupons
                        </button>
                        {/* tab btn */}
                        <button
                          className="btn_success tabView seeCoupon"
                          onClick={() =>
                            savingMapTab(pharmacyInfo, bestPackage, savingPercentage)
                          }
                        >
                          See coupons
                        </button>
                      </>)}

                    </div>

                  </motion.div>
                  <AnimatePresence>
                    {isShowPencilDiv && (
                      <motion.div
                        className="row mobileView-tabView"
                        initial={{ y: "100%" }}
                        animate={{ y: isShowPencilDiv ? 0 : "100%" }}
                        transition={{ duration: 0.5 }}
                        exit={{ y: "100%", transition: { duration: 0.5 } }}
                        layout
                        key={`${isShowPencilDiv}`}
                      >
                        <div className="col-sm-12 col-md-12 col-lg-12 ">
                          <motion.label
                            className="hrDiv"
                            initial={{
                              marginTop: "0px",
                              paddingTop: "0px",
                              width: "0%",
                            }}
                            animate={{
                              width: "100%",
                              paddingTop: "20px",
                              marginTop: "10px",
                            }}
                            exit={{
                              marginTop: "0px",
                              paddingTop: "0px",
                              width: "0%",
                              transition: { duration: 0.5, ease: "easeInOut" },
                            }}
                            transition={{ duration: 0.5, type: "tween" }}
                          >
                            PHARMACY
                          </motion.label>

                          <div className="review-coupon ">
                            <div className="row ">
                              <div className="col-3 col-sm-3 col-md-3 col-lg-3 autoMargin">
                                <div className="pharmacyImg_div">
                                  <img
                                    src={searchPharmacyLogo(pharmacyInfo?.pharmacyName, pharmacyInfo?.logo?.small, pharmacyInfo?.chain?.toLowerCase())}
                                    className="img-fluid"
                                    alt={pharmacyInfo?.pharmacyName + ' Logo'}
                                  // style={{maxWidth: 60}}
                                  />
                                </div>
                              </div>
                              <div className="col-7 col-sm-7 col-md-7 col-lg-7 p-0 autoMargin">
                                <div className="">
                                  <p className="shopName" style={{ textAlign: "left" }}>
                                    {((pharmacyInfo?.bestPrice > 0 || pharmacyInfo?.hasInsurance) && pharmacyInfo?.pharmacyName) ? pharmacyInfo?.pharmacyName : ""}
                                  </p>
                                </div>
                              </div>
                              <div className="col-2 col-sm-2 col-md-2 col-lg-2 autoMargin text-end">
                                <a href="javascript:void(0)" onClick={() => viewOptions()}>
                                  <i
                                    transition={{ type: "tween" }}
                                    whileHover={{
                                      scale: 1.2,
                                      transition: { duration: 0.2 },
                                      opacity: 1,
                                    }}
                                    whileTap={{ scale: 0.9 }}
                                    className="fa fa-pencil pointer"
                                    aria-label="Edit"
                                    alt="Edit"
                                  ></i>
                                </a>
                              </div>
                            </div>
                          </div>
                          {windowWidth < 992 && showHcdl && <HCDLSection containerClass="medications_inner medication_inner_fix" setShowHcdl={setShowHcdl} isPhone={true}/>}

                        </div>
                      </motion.div>
                    )}
                  </AnimatePresence>
                </motion.div>
                {windowWidth > 991 &&
                  <>
                    {cheaperOptionAvailable ?
                      <div className="offer-section">
                        <div style={{ display: "flex", alignItems: "center" }}><i class="fa-solid fa-circle-exclamation" style={{ fontSize: 21 }}></i><span>Your price may be lower at <a href="javascript:void(0)" onClick={() => viewOptions()}>a different pharmacy.</a></span>
                        </div>
                      </div>
                      :
                      <>
                        {(pharmacyInfo?.bestPrice > 0 || pharmacyInfo?.hasInsurance) && (
                          <div className="offer-section">
                            <div style={{ display: "flex", alignItems: "center" }}><i class="fa-solid fa-tag" style={{ color: "#40DFB9", fontSize: 26 }}></i><span>Congratulations! Your pharmacy has the lowest total price for your drugs in your area!</span>
                            </div>
                          </div>
                        )}
                      </>
                    }

                    {
                      showHcdl && <HCDLSection containerClass="medications_inner" setShowHcdl={setShowHcdl} isPhone={false} />
                    }
                  </>
                }
              </motion.div>
            </>)}
          </AnimatePresence>
        </>
      </section>
      {true &&
        <div className="col-sm-12 col-md-12 col-lg-3 couponMobFixed">
          <PopupCouponCard
            show={isOpenCouponModal}
            objParam={objPopupCoupon}
            close={() => hideCoupon()}
            navToCoupon={() => navigationToCoupon()}
            pencilAction={() => handlePencilAction()}
            drugPrices={medDrugData}
            ownPosition={ownPosition}
            cheaperOptionAvailable={cheaperOptionAvailable}
            windowWidth={windowWidth}
            insuranceDetail={insuranceDetail}
            originalFilter={valParam}
            setMemberAssign={setMemberAssign}
          />
        </div>
      }
    </>
  );
});

export default RightReviewCoupon;
